export default {
  namespaced: true,
  state: {
    submissionStatus: null,
  },
  getters: {
    submissionStatus(state) {
      return state.submissionStatus;
    },
  },
  mutations: {
    setSubmissionStatus(state, submissionStatus) {
      state.submissionStatus = submissionStatus;
    },
    resetSubmissionStatus(state) {
      state.submissionStatus = null;
    },
  },
  actions: {
    setSubmissionStatus({ commit }, submissionStatus) {
      commit("setSubmissionStatus", submissionStatus);
    },
    resetSubmissionStatus({ commit }) {
      commit("resetSubmissionStatus");
    },
  },
};
