import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import Buefy from 'buefy';
import helpers from "@/helpers/helpers";
import VueSignaturePad from 'vue-signature-pad';
import '@/assets/sass/main.scss'
import '@mdi/font/css/materialdesignicons.css'


//Auth
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm';

// Axios modifications
axios.defaults.baseURL = process.env.NODE_ENV === "production" ? 'https://api.el-ga.de/app' : 'http://127.0.0.1:8080/app';

//Global fix to modify default toJSON/toISOString from '2022-11-20T23:00:00.000Z' to '2022-11-20T23:00:00Z'
//because Vapor/Swift does only understand latter to parse into Date object
Date.prototype.toJSON = function () {
  return this.toISOString().slice(0,-5)+"Z"
}

//Axios global registration
Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios
    Vue.api = axios
  }
})

Vue.config.productionTip = false
/**
 * Auth
 */
Vue.use(auth, {
  plugins: {
    http: axios, // Axios
    // http: Vue.http, // Vue Resource
    router: router,
  },
  drivers: {
    auth:   driverAuthBearer,
    http:   driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: 'type',
    notFoundRedirect: { name: 'login' },
    fetchData: { url: 'auth/me' },
    refreshData: { url: 'auth/refresh' },
    loginData: { url: 'auth/login' },
    parseUserData: function(data) {
      let dubbingActor = data.dubbingActors.find((actor) => actor.company.id === store.state.company.id)
      store.dispatch('selectDubbingActor', dubbingActor)
      return data
    }
  },
});

// Buefy
Vue.use(Buefy)

Vue.use(VueSignaturePad)

// Add helpers
Vue.use({
          install(Vue) {
            Vue.prototype.$helpers = helpers
            Vue.helpers = helpers
          }
        })

new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit('initialiseStore');},
  render: h => h(App)
}).$mount('#app')
