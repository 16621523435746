import Vue from 'vue'
import Vuex from 'vuex'

import osdbCoreDataSet from './osdb-core-data-set'

Vue.use(Vuex)

export default new Vuex.Store({
  state:     {
    company: null,
    dubbingActor: null,
  },
  getters:   {
    company(state) {
      return state.company
    },
    dubbingActor(state) {
      return state.dubbingActor
    },
  },
  mutations: {
    initialiseStore(state) {
      let company = JSON.parse(localStorage.getItem('company'))
      if (company) {
        state.company = company
      }
    },
    setCompany(state, company) {
      localStorage.setItem('company', JSON.stringify(company));
      state.company = company
    },
    setDubbingActor(state, dubbingActor) {
      state.dubbingActor = dubbingActor
    },
  },
  actions:   {
    selectCompany({commit}, company) {
      commit("setCompany", company)
    },
    selectDubbingActor({commit}, dubbingActor) {
      commit("setDubbingActor", dubbingActor)
    },
  },
  modules:   {
    osdbCoreDataSet: osdbCoreDataSet
  }
})